import React from 'react';
import {
  Typography,
  Stack,
  FormControl,
  TextField,
  Box,
  IconButton,
  Select,
  InputLabel,
  MenuItem,
  Button,
  Tooltip,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  MailParamType,
  MailParamEmptyValues,
  MailTemplateType,
  MailTemplateBlackList,
} from '../../propTypes';
import { useAddControlGroup, useCreateUpdateMailTemplate } from '../../hooks';
import { DownloadExcelIcon } from '../icons';

const validationSchema = Yup.object({
  version: Yup.string().required('La versión es requerida'),
});

const MailTemplateForm = ({
  mailParams,
  selectedMailParam,
  setSelectedMailParam,
  mailVersionsForParam,
  companyId,
  editMode,
  setEditMode,
  showUploadFile,
  setShowUploadFile,
  blackListTemplate,
  setBlackListTemplate,
  setSelectedMail,
}) => {
  const isBlackListInVersions = !!mailVersionsForParam.find((template) => template.version === 'BLACKLIST');
  const createBlackList = !isBlackListInVersions && blackListTemplate;

  const { addControlGroup, loading: loadingControlGroup } = useAddControlGroup({
    blackListTemplate,
  });
  const { createUpdateMailTemplate, loading } = useCreateUpdateMailTemplate({
    editMode,
    companyId,
    addControlGroupBlackList: blackListTemplate,
    addControlGroup,
    addControlGroupByFile: false,
    setMailParamId: null,
    setSelectedMailParam,
    setSelectedMail,
  });

  const selectMailParam = (version) => {
    if (version === 'BLACKLIST' && !isBlackListInVersions) {
      setBlackListTemplate(true);
      setSelectedMailParam((prevState) => ({
        ...prevState,
        template: MailTemplateBlackList,
      }));
      return;
    }
    setBlackListTemplate(false);
    setSelectedMailParam(mailParams.find((param) => param.template.version === version));
  };

  return (
    <Formik
      initialValues={
        selectedMailParam.template || MailParamEmptyValues.template
      }
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        const id = (editMode && !blackListTemplate) ? selectedMailParam?.template.id : null;
        // if there is a company, you can assign control groups
        // if not, you can create/edit mail templates
        if (!companyId || createBlackList) {
          const submissionValues = {
            id,
            version: values.version,
            template: blackListTemplate ? null : values.template,
            mailParameter: selectedMailParam?.id,
          };
          createUpdateMailTemplate({ variables: submissionValues });
        } else {
          const submissionValues = {
            companyId,
            mailParameterId: selectedMailParam.id,
          };
          addControlGroup({ variables: submissionValues });
        }
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <Stack spacing={3} color="gray">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                <Typography variant="h6">
                  {editMode
                    ? 'Plantilla actual'
                    : `Nueva plantilla para
                  ${selectedMailParam?.requestingPlatform.value} ${selectedMailParam?.country?.value}`}
                </Typography>
                {editMode && !companyId && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      setEditMode(false);
                      setFieldValue('version', '');
                      setFieldValue('template', '');
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                )}
                {!editMode && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      setEditMode(true);
                      setFieldValue(
                        'version',
                        selectedMailParam?.template.version,
                      );
                      setFieldValue(
                        'template',
                        selectedMailParam?.template.template,
                      );
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </Stack>
              <Box>
                {!companyId && !showUploadFile && (
                  <Button
                    title="Carga masiva para esta plantilla"
                    onClick={() => setShowUploadFile(true)}
                    disabled={!selectedMailParam?.id}
                    variant="contained"
                    size="small"
                    color="success"
                    startIcon={<DownloadExcelIcon fontSize="small" />}
                  >
                    Carga masiva
                  </Button>
                )}
              </Box>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Box sx={{ width: '25%' }}>
                <FormControl fullWidth>
                  {!editMode ? (
                    <Field
                      as={TextField}
                      name="version"
                      label="Versión"
                      onChange={(e) => {
                        setFieldValue('version', e.target.value);
                      }}
                    />
                  ) : (
                    <>
                      <InputLabel id="demo-simple-select-label">
                        Versión
                      </InputLabel>
                      <Field
                        as={Select}
                        name="version"
                        label="Versión"
                        onChange={(e) => {
                          setFieldValue('version', e.target.value);
                          selectMailParam(e.target.value);
                        }}
                      >
                        {!isBlackListInVersions && (
                          <MenuItem key={0} value="BLACKLIST">BLACKLIST</MenuItem>
                        )}
                        {mailVersionsForParam.map((template) => (
                          <MenuItem
                            key={template.id}
                            value={template.version}
                          >
                            {template.version}
                          </MenuItem>
                        ))}
                      </Field>
                    </>
                  )}
                  <Typography color="error" variant="caption">
                    <ErrorMessage name="version" />
                  </Typography>
                </FormControl>
              </Box>
              {!blackListTemplate && (
                <FormControl fullWidth>
                  <Field
                    as={TextField}
                    name="template"
                    label="Sendgrid id"
                    fullWidth
                    onChange={(e) => { setFieldValue('template', e.target.value); }}
                    disabled={editMode}
                  />
                  <Typography color="error" variant="caption">
                    <ErrorMessage name="template" />
                  </Typography>
                </FormControl>
              )}
              <Tooltip title={editMode ? 'Asignar plantilla al mail' : 'Crear plantilla para el mail'}>
                <IconButton
                  type="submit"
                  size="small"
                  disabled={loading || loadingControlGroup || (!companyId && editMode)}
                  loading={loading}
                  color="primary"
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

MailTemplateForm.propTypes = {
  mailParams: PropTypes.arrayOf(MailParamType),
  selectedMailParam: MailParamType,
  setSelectedMailParam: PropTypes.func.isRequired,
  mailVersionsForParam: PropTypes.arrayOf(MailTemplateType),
  companyId: PropTypes.string,
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  showUploadFile: PropTypes.bool.isRequired,
  setShowUploadFile: PropTypes.func.isRequired,
  blackListTemplate: PropTypes.bool.isRequired,
  setBlackListTemplate: PropTypes.func.isRequired,
  setSelectedMail: PropTypes.func.isRequired,
};

MailTemplateForm.defaultProps = {
  mailParams: [],
  selectedMailParam: null,
  mailVersionsForParam: [],
  companyId: null,
};

export default MailTemplateForm;
