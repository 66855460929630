import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HeaderStack from '../stacks/HeaderStack';

const BasicHeader = ({ title, subtitle, showGoBack }) => (
  <HeaderStack showGoBack={showGoBack}>
    <Box>
      <Typography variant="h1" color="primary.main">
        {title}
      </Typography>
      {subtitle && <Typography variant="headerSubtitle">{subtitle}</Typography>}
    </Box>
  </HeaderStack>
);

BasicHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  showGoBack: PropTypes.bool,
};

BasicHeader.defaultProps = {
  showGoBack: false,
  subtitle: '',
};

export default BasicHeader;
