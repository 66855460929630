import React from 'react';
import {
  Typography,
  Stack,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackBars } from '../../hooks';
import { CREATE_UPDATE_MAIL, GET_ASM } from '../../graphql';
import { IP_POOL_TYPES } from '../../constants/ip-pool-types';
import { MailBaseType, MailEmptyValues, formatSelectedMail } from '../../propTypes';

const validationSchema = Yup.object({
  emailName: Yup.string().required('El nombre del mail es requerido'),
  ipPoolName: Yup.string().required('El nombre del pool IP es requerido'),
});

const MailForm = ({ selectedMail, setSelectedMail, setOpenCreateMailForm }) => {
  const { addAlert } = useSnackBars();
  const { data: queryDataASM, loading: loadingAsmTypes } = useQuery(GET_ASM);
  const asmTypes = queryDataASM?.getAsm || [];

  const [createUpdateMail, { loading }] = useMutation(CREATE_UPDATE_MAIL, {
    onCompleted: (data) => {
      addAlert({
        id: 'new-mail',
        message: selectedMail?.id ? 'Mail actualizado' : 'Mail creado',
      });
      setSelectedMail(formatSelectedMail(data.createUpdateMail.mail));
      setOpenCreateMailForm(false);
    },
    onError: (error) => {
      if (error.message.includes('unique constraint')) {
        addAlert({
          id: 'new-mail-error-duplicate',
          message: 'El mail ya existe',
          severity: 'error',
        });
      } else {
        addAlert({
          id: 'new-mail-error',
          message: selectedMail?.id ? 'Error al actualizar el mail' : 'Error al crear el mail',
          severity: 'error',
        });
      }
    },
  });

  return (
    <Formik
      initialValues={selectedMail || MailEmptyValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        const submissionValues = {
          id: selectedMail?.id,
          emailName: values.emailName,
          ipPoolName: values.ipPoolName,
          asm: values.asm,
        };
        createUpdateMail({ variables: submissionValues });
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <Typography variant="h5" color="primary" sx={{ marginBottom: '1em' }}>
            {selectedMail?.id ? 'Editar mail' : 'Crear nuevo mail'}
          </Typography>
          <Stack spacing={3} color="gray">
            <FormControl fullWidth>
              <Field
                as={TextField}
                name="emailName"
                label="Nombre del mail"
                fullWidth
              />
              <Typography color="error" variant="caption">
                <ErrorMessage name="emailName" />
              </Typography>
            </FormControl>
            <FormControl fullWidth>
              <Field
                as={Select}
                name="asm"
                displayEmpty
                fullWidth
                onChange={(e) => setFieldValue('asm', e.target.value)}
              >
                <MenuItem value={null}>Elige un ASM</MenuItem>
                {!loadingAsmTypes && asmTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>{type.groupName}</MenuItem>
                ))}
              </Field>
              <Typography color="error" variant="caption">
                <ErrorMessage name="asm" />
              </Typography>
            </FormControl>
            <FormControl fullWidth>
              <Field
                as={Select}
                name="ipPoolName"
                displayEmpty
                fullWidth
                onChange={(e) => setFieldValue('ipPoolName', e.target.value)}
              >
                <MenuItem value={null}>Elige un nombre pool IP</MenuItem>
                {Object.entries(IP_POOL_TYPES).map(([key, value]) => (
                  <MenuItem key={key} value={value}>{value}</MenuItem>
                ))}
              </Field>
              <Typography color="error" variant="caption">
                <ErrorMessage name="ipPoolName" />
              </Typography>
            </FormControl>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              loading={loading}
              fullWidth
              sx={{
                width: '50%',
                maxWidth: '150px',
                height: '100%',
                alignSelf: 'flex-start',
                fontSize: '12px',
              }}
            >
              {selectedMail?.id ? 'Guardar' : 'Crear'}
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

MailForm.propTypes = {
  selectedMail: MailBaseType,
  setSelectedMail: PropTypes.func.isRequired,
  setOpenCreateMailForm: PropTypes.func.isRequired,
};

MailForm.defaultProps = {
  selectedMail: MailEmptyValues,
};

export default MailForm;
