import React from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useBooleanState } from '../../hooks';
import EmbedYouTubeDialog from '../embedYouTubeDialog.js/EmbedYoutubeDialog';

const HelpVideo = ({ helpVideo }) => {
  const [openVideo, toggleOpenVideo] = useBooleanState();
  return (
    <>
      <IconButton
        onClick={toggleOpenVideo}
        sx={{ bgcolor: 'warning.main' }}
        color="fingoWhite"
        size="small"
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <LightbulbIcon fontSize="small" />
      </IconButton>
      <EmbedYouTubeDialog
        videoId={helpVideo}
        openVideo={openVideo}
        toggleOpenVideo={toggleOpenVideo}
      />
    </>
  );
};

HelpVideo.propTypes = {
  helpVideo: PropTypes.string.isRequired,
};

export default HelpVideo;
