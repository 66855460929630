import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

const PADDINGS = { xs: 10, md: 20 };
const WelcomeHeader = ({ topMessage, headerMessage, bottomMessage, ...props }) => (
  <Box sx={{ ...props }}>
    <Typography
      variant="h2"
      align="center"
      color="primary.main"
      sx={{
        fontSize: { xs: 30, md: 55 },
        position: 'relative',
        top: { xs: 20, md: 20 },
        pr: PADDINGS,
        opacity: 0.08,
      }}
    >
      {topMessage}
    </Typography>
    <Typography
      variant="h2"
      align="center"
      color="primary.main"
      sx={{
        fontSize: { xs: '25px !important', md: '35px !important' },
      }}
    >
      {headerMessage}
    </Typography>
    <Typography
      variant="h2"
      align="center"
      color="primary.main"
      sx={{
        fontSize: { xs: 30, md: 55 },
        position: 'relative',
        bottom: { xs: 22, md: 22 },
        pl: PADDINGS,
        opacity: 0.08,
      }}
    >
      {bottomMessage}
    </Typography>
  </Box>
);

WelcomeHeader.propTypes = {
  topMessage: PropTypes.string.isRequired,
  headerMessage: PropTypes.string.isRequired,
  bottomMessage: PropTypes.string.isRequired,
};

export default WelcomeHeader;
