import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import HeaderStack from '../stacks/HeaderStack';
import { PREOFFERS, OFFERS } from '../../constants/shopping-carts';
import ShoppingCartIconButton from '../buttons/ShoppingCartIconButton';
import HeaderSearcherInput from '../inputs/HeaderSearcherInput';

const ShoppingCartSearchHeader = ({
  title,
  shoppingCartType,
  subtitle,
  showGoBack,
  setHeaderFilters,
}) => {
  const [globalFilter, setGlobalFilter] = useState('');

  const onChangeHandler = (filter) => {
    setGlobalFilter(filter);
    setHeaderFilters((prevState) => ({ ...prevState, globalFilter: filter }));
  };

  return (
    <HeaderStack title={title} subtitle={subtitle} showGoBack={showGoBack}>
      <Stack sx={{ flexGrow: 1 }} direction="row">
        <Box sx={{ marginLeft: 'auto' }}>
          <HeaderSearcherInput finder={{ onFinderChange: onChangeHandler, searchPlaceHolder: 'Buscar', finderValue: globalFilter }} />
        </Box>
        <Box sx={{ mx: 1 }} alignContent="center">
          <ShoppingCartIconButton cartType={shoppingCartType} />
        </Box>
      </Stack>
    </HeaderStack>
  );
};

ShoppingCartSearchHeader.propTypes = {
  title: PropTypes.string.isRequired,
  shoppingCartType: PropTypes.oneOf([PREOFFERS, OFFERS]).isRequired,
  subtitle: PropTypes.string,
  showGoBack: PropTypes.bool,
  setHeaderFilters: PropTypes.func,
};

ShoppingCartSearchHeader.defaultProps = {
  showGoBack: false,
  subtitle: '',
  setHeaderFilters: () => {},
};

export default ShoppingCartSearchHeader;
