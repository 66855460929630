import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SuccessErrorIcon from '../icons/SuccessErrorIcon';

const CardHeader = ({ status, primaryText }) => (
  <Stack direction="row" alignItems="center" px={3} pt={2} pb={1}>
    <Typography variant="cardHeader" mr={1}>
      {primaryText}
    </Typography>
    {status !== null && <SuccessErrorIcon status={status} />}
  </Stack>
);

CardHeader.propTypes = {
  status: PropTypes.bool,
  primaryText: PropTypes.string.isRequired,
};

CardHeader.defaultProps = {
  status: null,
};

export default CardHeader;
