import React from 'react';
import {
  Typography,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { MailParamType, MailParamEmptyValues, formatSelectedMailParam, MailType } from '../../propTypes';
import { useSnackBars } from '../../hooks';
import { CREATE_UPDATE_MAIL_PARAMETERS, GET_REQUESTING_PLATFORMS, GET_COUNTRIES } from '../../graphql';
import { UserMailFilter } from '../filters';

const validationSchema = Yup.object({
  requestingPlatform: Yup.object().shape({ id: Yup.string().required('La plataforma solicitante es requerido') }),
  country: Yup.object().shape({ id: Yup.string().required('El país id es requerido') }),
});

const MailParamForm = ({
  selectedMail,
  setSelectedMail,
  selectedMailParam,
  setSelectedMailParam,
  setOpenCreateMailParamForm,
}) => {
  const { addAlert } = useSnackBars();
  const {
    data: queryDataPlatforms, loading: loadingPlatforms,
  } = useQuery(GET_REQUESTING_PLATFORMS);
  const requestingPlatforms = queryDataPlatforms?.getRequestingPlatforms || [];
  const { data: queryDataCountries, loading: loadingCountries } = useQuery(GET_COUNTRIES);
  const countries = queryDataCountries?.getCountries || [];
  const editmode = selectedMailParam?.id;

  const [createUpdateMailParam, { loading }] = useMutation(CREATE_UPDATE_MAIL_PARAMETERS, {
    onCompleted: (data) => {
      addAlert({
        id: 'new-mail-parameter',
        message: editmode ? 'Detalle del mail actualizado' : 'Detalle del mail creado',
      });
      const formatedParam = formatSelectedMailParam(data.createUpdateMailParameters.mailParameters);
      const existingParams = selectedMail.mailParams || [];
      const paramExists = existingParams.some((param) => param.id === formatedParam.id);
      if (!paramExists) {
        const mailParamsList = [...selectedMail.mailParams, formatedParam];
        setSelectedMail((prev) => ({
          ...prev,
          mailParams: mailParamsList,
        }));
      }
      setSelectedMailParam(formatedParam);
      setOpenCreateMailParamForm(false);
    },
    onError: (error) => {
      if (error.message.includes('unique constraint')) {
        addAlert({
          id: 'new-mail-error-duplicate',
          message: 'La combinación del mail { país - plataforma - template } ya existe',
          severity: 'error',
        });
      } else {
        addAlert({
          id: 'new-mail-error',
          message: editmode ? 'Error al actualizar detalle del mail' : 'Error al crear detalle del mail',
          severity: 'error',
        });
      }
    },
  });

  return (
    <>
      <Formik
        initialValues={selectedMailParam || MailParamEmptyValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values) => {
          const submissionValues = {
            id: editmode,
            mail: selectedMail.id,
            country: values.country?.id,
            requestingPlatform: values.requestingPlatform?.id,
            fromEmail: values.from?.id,
            mailTemplate: selectedMailParam?.template?.id,
            to: values.to.map((to) => to.id),
            cc: values.cc.map((cc) => cc.id),
            bcc: values.bcc.map((bcc) => bcc.id),
          };
          createUpdateMailParam({ variables: submissionValues });
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Typography variant="h5" color="primary" sx={{ marginBottom: '1em' }}>
              {editmode ? 'Editar detalle' : 'Crear nuevos parámetros para el mail'}
            </Typography>
            <Stack spacing={3} color="gray">
              <Stack direction="row" spacing={1}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Plataforma solicitante</InputLabel>
                  <Field
                    as={Select}
                    name="requestingPlatform.id"
                    labelId="demo-simple-select-label"
                    label="Plataforma solicitante"
                    fullWidth
                    onChange={(e) => setFieldValue('requestingPlatform.id', e.target.value)}
                  >
                    {!loadingPlatforms && requestingPlatforms.map((type) => (
                      <MenuItem key={type.id} value={type.id}>{type.code}</MenuItem>
                    ))}
                  </Field>
                  <Typography color="error" variant="caption">
                    <ErrorMessage name="requestingPlatform.id" />
                  </Typography>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">País</InputLabel>
                  <Field
                    as={Select}
                    name="country.id"
                    labelId="demo-simple-select-label"
                    label="País"
                    fullWidth
                    onChange={(e) => setFieldValue('country.id', e.target.value)}
                  >
                    {!loadingCountries && countries.map((type) => (
                      <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                    ))}
                  </Field>
                  <Typography color="error" variant="caption">
                    <ErrorMessage name="country.id" />
                  </Typography>
                </FormControl>
              </Stack>
              <Stack direction="column" spacing={2}>
                <FormControl fullWidth>
                  <UserMailFilter id="from" name="from" label="Mail Desde" user={values.from} setFieldValue={setFieldValue} />
                  <Typography color="error" variant="caption">
                    <ErrorMessage name="from" />
                  </Typography>
                </FormControl>
                <UserMailFilter id="bcc" name="bcc" label="BCC" userList={values.bcc} setFieldValue={setFieldValue} mode="multiple" set />
                <UserMailFilter id="cc" name="cc" label="CC" userList={values.cc} setFieldValue={setFieldValue} mode="multiple" />
                <UserMailFilter id="to" name="to" label="Para" userList={values.to} setFieldValue={setFieldValue} mode="multiple" />
              </Stack>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                loading={loading}
                fullWidth
                sx={{
                  width: '50%',
                  maxWidth: '150px',
                  height: '100%',
                  alignSelf: 'flex-start',
                  fontSize: '12px',
                }}
              >
                {editmode ? 'Guardar' : 'Crear'}
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

MailParamForm.propTypes = {
  selectedMail: MailType.isRequired,
  setSelectedMail: PropTypes.func.isRequired,
  selectedMailParam: MailParamType,
  setSelectedMailParam: PropTypes.func.isRequired,
  setOpenCreateMailParamForm: PropTypes.func.isRequired,
};

MailParamForm.defaultProps = {
  selectedMailParam: MailParamEmptyValues,
};

export default MailParamForm;
